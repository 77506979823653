import React from "react";
import { Typography, useTheme } from "@mui/material";

const HomeHeading = ({ isAuthenticated }) => {
  // Receive the isAuthenticated prop
  const theme = useTheme();

  return (
    <div style={{ textAlign: "center" }}>
      {" "}
      {/* Center the content */}
      <Typography
        component="h1"
        sx={{
          fontFamily: "sans-serif",
          fontWeight: "bold",
          fontSize: {
            xs: "10vw",
            sm: "8vw",
            md: "5.5vw",
            lg: "4vw",
          },
        }}
      >
        {isAuthenticated ? "Awesome! You made it..." : "Welcome to LILLUP!"}
      </Typography>
      <Typography
        component="p"
        sx={{
          fontFamily: "Montserrat",
          fontWeight: "normal",
          fontSize: {
            xs: "5vw", // Responsive font size for the paragraph
            sm: "4vw",
            md: "2.5vw",
            lg: "2vw",
          },
          marginTop: theme.spacing(1), // Add some margin for spacing
        }}
      >
        {isAuthenticated ? "" : "Where we help you to LEAD YOUR LIFE"}
      </Typography>
      {/* Display an additional message based on authentication status */}
      <Typography
        component="p"
        sx={{
          fontFamily: "Arial",
          fontWeight: "bold",
          fontSize: {
            xs: "5vw",
            sm: "3vw",
            md: "1.8vw",
            lg: "1.3vw",
          },
          marginTop: theme.spacing(4), // Add margin for spacing
          color: isAuthenticated ? "green" : "red", // Different color based on authentication status
        }}
      >
        {isAuthenticated
          ? "Welcome to the exclusive section of LILLUP. Feel free to explore!"
          : "Please log in to continue."}
      </Typography>
    </div>
  );
};

export default HomeHeading;
