import React from "react";
import Button from "@mui/material/Button";
import { Grid, Box } from "@mui/material";
import { Link, useLocation } from "react-router-dom";


const buttons = [
  { label: "LinkedIn", path: "" },
  { label: "Scribe", path: "" },
  { label: "Generative UI", path: "" },
  { label: "Acquired Knowledge", path: "" },
  { label: "Maintain Knowledge", path: "" },
  { label: "Find a Job", path: "" },
  { label: "M.E.S", path: "" },
  { label: "Digital Twins", path: "" },
  { label: "Resume", path: "" },
  { label: "Multi Bots", path: "" },
  { label: "Learners Video", path: "" },
  { label: "NFT", path: "" },
  { label: "QR Code", path: "" },
  { label: "Monetizing", path: "" },
];

const ButtonGrid = () => {
  const location = useLocation();
  const isAuthenticated = location.state?.isAuthenticated;

  return (
    <Box>
      {isAuthenticated ? ("WRONG PAGE YOU AUTHROZATION") : (<Box
      sx={{
        height: {
          xs: "auto",
          sm: "auto",
          md: "100vh",
        },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "darkorange",
      }}
    >
      <Grid container spacing={3} justifyContent="center" sx={{ width: "70%" }}>
        {buttons.map((button, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Link to={button.path} style={{ textDecoration: "none" }}>
              <Button
                fullWidth
                variant="contained"
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  padding: "1px 0",
                  fontWeight: "bold",
                  fontSize: "16px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                {button.label}
              </Button>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>)}
    </Box>    
  );
};

export default ButtonGrid;
