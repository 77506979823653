import React, { useState } from "react"; // Import useState
import HomeButtonGrid from "../../components/HomeButtonGrid/homeButtons";
import "./Home.css";
import { Helmet } from "react-helmet";
import HomeHeading from "./words";
import LogoutButton from "../../components/Common/logoutComponent";


function Home() {
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Manage authentication state

  // Define a function to update authentication status
  const handleAuthenticationChange = (authenticated) => {
    setIsAuthenticated(authenticated);
  };

  return (
    <div className="homeT">
      <Helmet>
        <title>Lillup - Home Page</title>
      </Helmet>
      <HomeHeading isAuthenticated={isAuthenticated} />{" "}
      {/* Pass authentication state */}
      <HomeButtonGrid onAuthenticationChange={handleAuthenticationChange} />
      <LogoutButton />
    </div>
  );
}


export default Home;

