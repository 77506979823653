import React, { useState } from "react";
import ButtonGrid from "../../components/TalentButtonGrid/talentButtons";
import "./Talents.css";
import { Helmet } from "react-helmet";

function TalentPassport() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Define a function to update authentication status
  const handleAuthenticationChange = (authenticated) => {
    setIsAuthenticated(authenticated);
  };

  return (
    <div className="mainT">
      <Helmet>
        <title>Lillup - Talent Passport Page</title>
      </Helmet>
      <div className="scroll">
        <ButtonGrid
          isAuthenticated={isAuthenticated}
          onAuthenticationChange={handleAuthenticationChange}
        />
      </div>
    </div>
  );
}

export default TalentPassport;
