// export default HomeButtonGrid;

import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import homeButtonsData from "./homeButtonsData";
import ButtonComponent from "../Common/buttonComponent";
import { useNavigate, useLocation } from "react-router-dom";
import LogoutButton from "../Common/logoutComponent";

const HomeButtonGrid = ({ onAuthenticationChange }) => {
  // Receive onAuthenticationChange prop
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate(); // Hook to handle navigation
  const location = useLocation(); // Get the current location

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordSubmit = () => {
    if (password === "correctPassword") {
      setIsAuthenticated(true);
      localStorage.setItem("isAuthenticated", "true");
      onAuthenticationChange(true); // Update authentication status in Home component

      // Navigate to the previous page if exists, otherwise navigate to default
      const from = location.state?.from || "/"; // Default to home page if no previous location
      navigate(from, { replace: true }); // Navigate to the previous page
    } else {
      setMessage("Incorrect password please try again");
      onAuthenticationChange(false); // Ensure false if incorrect
    }
  };

  // Effect to redirect based on authentication state
  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/", { replace: true, state: { from: location.pathname } });
    }
  }, [isAuthenticated, navigate, location.pathname]);

  // Handle logout and reset authentication
  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem("isAuthenticated");
    setPassword(""); // Clear password field
    navigate("/", { replace: true }); // Replace history after logout
  };

  return (
    <div style={{ padding: "50px", backgroundColor: "#FF8C00" }}>
      {!isAuthenticated ? (
        <div style={{ textAlign: "center" }}>
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            placeholder="Enter password"
            style={{ padding: "10px", fontSize: "16px" }}
          />
          <button
            onClick={handlePasswordSubmit}
            style={{
              marginLeft: "0px",
              padding: "10px 20px",
              fontSize: "16px",
              cursor: "pointer",
              marginTop: "15px",
            }}
          >
            Submit
          </button>
          {message && (
            <div
              style={{
                color: "white",
                marginTop: "10px",
                backgroundColor: "red",
                padding: "7px",
                fontWeight: "bold",
              }}
            >
              {message}
            </div>
          )}{" "}
          {/* Display message */}
        </div>
      ) : (
        <Grid container spacing={3} justifyContent="center">
          {homeButtonsData.map((button, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              {button.label && (
                <ButtonComponent label={button.label} path={button.path} />
              )}
            </Grid>
          ))}
          <LogoutButton
            onClick={handleLogout}
            isAuthenticated={isAuthenticated}
          />
        </Grid>
      )}
    </div>
  );
};

export default HomeButtonGrid;
