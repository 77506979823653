import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import TalentPassport from './pages/TalentPassport/Talents';


function App() {
  return (
    <Router>
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/talent-passport" element={<TalentPassport />} />
    </Routes>
  </Router>
);
}

export default App;