// import React from "react";
// import { Button } from "@mui/material";


// // LogoutButton receives the onLogout function from the parent component
// const LogoutButton = ({ isAuthenticated }) => {
//   return (
//     <div>
//       {isAuthenticated
//         ? <Button
//         sx={{ padding: "10px 20px", fontSize: "16px" }}
//         variant="contained"
//         color="error"
//       >
//         Logout
//       </Button>
//         : ""}
//     </div>
//   );
// };

// export default LogoutButton;


import React from "react";
import { Button } from "@mui/material";

const LogoutButton = ({ onClick, isAuthenticated }) => {
  return (
    <>
      {isAuthenticated && ( // Only show button if authenticated
        <Button
          onClick={onClick}
          variant="contained"
          color="error"
          sx={{ padding: "10px 20px", fontSize: "16px", marginTop: "30px" }}
        >
          Logout
        </Button>
      )}
    </>
  );
};

export default LogoutButton;
