// src/components/Common/ButtonComponent.js
import React from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const ButtonComponent = ({ label, path }) => {
  // Preventing potential XSS by ensuring label is a string
  if (typeof label !== "string") {
    console.error("Invalid label type. Expected a string.");
    return null;
  }

  return (
    <Link to={path} style={{ textDecoration: "none" }}>
      <Button
        fullWidth // Make the button full width inside its grid item      
        variant="contained"
        sx={{
          backgroundColor: "white",
          color: "black",
          padding: "8px 16px",
          fontWeight: "bold",
          fontSize: "16px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          "&:hover": {
            backgroundColor: "#f0f0f0",
          },    
        }}
      >
        {label}
      </Button>
    </Link>
  );
};

ButtonComponent.propTypes = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default ButtonComponent;
